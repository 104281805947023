import React, { Component, useContext } from "react";
import { Router } from "@reach/router";
import { ApolloProvider } from "react-apollo";
import { getClient } from "./api";
import Home from "./components/routes/Home";
import Map from "./components/routes/Map";
import Data from "./components/routes/Data";
import Login from "./components/routes/Login";
import SignUp from "./components/routes/Signup";
import ForgotPassword from "./components/routes/ForgotPassword";
import Admin from "./components/routes/Admin";
import CRBasic from "./components/CRBasic";
import { Provider } from "react-redux";
import { ReactComponent as HomeIcon } from "./icons/home.svg";
import { ReactComponent as StationIcon } from "./icons/station.svg";
import { ReactComponent as UserIcon } from "./icons/user.svg";
import { ReactComponent as ManageIcon } from "./icons/manage.svg";
import { ReactComponent as MapIcon } from "./icons/map.svg";
import { ReactComponent as CodeIcon } from "./icons/code.svg";
import { ReactComponent as DataIcon } from "./icons/data.svg";
import { ContextAuth, ContextAuthProvider } from "./reducers/AuthContext";
import { ContextAppProvider } from "./reducers/AppContext";
import { Playground, store } from "graphql-playground-react";
import { GRAPHQL_API_URL, GRAPHQL_API_WS } from "./api";

export const UserContext = {};

const style = { maxWidth: "30px", maxHeight: "30px", margin: "15px 0" };

export const routes = [
  {
    component: () => <UserIcon alt="U" style={style} />,
    needAuth: true,
    name: "Account",
    route: "/me",
    minUserGroupId: 3,
    displayOnHeader: true
  },
  {
    component: () => <HomeIcon alt="U" style={style} />,
    needAuth: true,
    name: "Stations",
    route: "/stations",
    minUserGroupId: 3,
    displayOnHeader: true
  },
  // {
  //   component: () => <MapIcon alt="M" style={style} />,
  //   needAuth: true,
  //   name: "Maps",
  //   route: "/map/0/0",
  //   minUserGroupId: 3,
  //   displayOnHeader: true
  // },
  // {
  //   component: () => <DataIcon alt="A" style={style} />,
  //   needAuth: true,
  //   name: "Docs",
  //   route: "http://dl-mqtt.campbellsci.cloud",
  //   minUserGroupId: 3,
  //   displayOnHeader: true
  // },
  // {
  //   component: () => <DataIcon alt="D" style={style} />,
  //   needAuth: true,
  //   name: "Data Explorer",
  //   route: "/data",
  //   minUserGroupId: 3,
  //   displayOnHeader: true
  // },
  {
    component: () => <ManageIcon alt="M" style={style} />,
    needAuth: true,
    name: "Manage",
    route: "/manage",
    minUserGroupId: 3,
    displayOnHeader: true
  },
  {
    component: () => <CodeIcon alt="P" style={style} />,
    needAuth: true,
    name: "API Explorer",
    route: "/playground",
    minUserGroupId: 1,
    displayOnHeader: true
  }
];

class App extends Component {
  render() {
    // console.log("app state", this.state);
    return (
      <ContextAuthProvider>
        <ContextAppProvider>
          <StationIcon alt="S" style={style} />
          <Router>
            <PrivateRoute path="/stations" as={Home} />
            <PrivateRoute path="/*" as={Home} />
            <PrivateRoute path="/map/:lat/:lng" as={Map} />
            <PrivateRoute path="/data" as={Data}>
              <PrivateRoute path="/value/:key" as={Data} />
            </PrivateRoute>
            <PrivateRoute path="/data/:device/:table" as={Data} />
            <PrivateRoute path="/data/:device" as={Data} />
            <PrivateRoute path="/program/crbasic/:program_id" as={CRBasic} />
            <PrivateRoute path="/manage" as={Admin} />
            <PrivateRoute path="/playground" as={GraphqlPlayground} />
            <SignUp path="/signup" />
            <Login path="/login/:func/:token" />
            <Login path="/login" />
            <ForgotPassword path="/forgotpassword" />
          </Router>
        </ContextAppProvider>
      </ContextAuthProvider>
    );
  }
}

function PrivateRoute(props) {
  let { state } = useContext(ContextAuth);
  // console.log("PrivateRoute", state);
  const client = getClient(
    state.token ? state.token : localStorage.getItem("cs-auth")
  );
  let { as: Comp } = props;
  return state.user ? (
    <ApolloProvider client={client}>
      <Comp {...props} />
    </ApolloProvider>
  ) : (
    <React.Fragment>
      <Login />
    </React.Fragment>
  );
}

function GraphqlPlayground(props) {
  return (
    <Provider {...props} store={store} color={"#FFF"}>
      <Playground
        endpoint={GRAPHQL_API_URL}
        subscriptionEndpoint={GRAPHQL_API_WS}
        headers={{ "x-auth-token": localStorage.getItem("cs-auth") }}
        settings={{ "editor.reuseHeaders": true }}
        setTitle="CS GraphQL"
        color={"#FFF"}
      />
    </Provider>
  );
}

export default App;


