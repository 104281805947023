import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import AuthReducer from "./reducers/AuthContext";
import FileReducer from "./reducers/FileContext";
// import DeviceReducer from "./reducers/DeviceContext";
import AppReducer from "./reducers/AppContext";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    app: AppReducer,
    // device: DeviceReducer,
    auth: AuthReducer,
    file: FileReducer
  }),
  composeEnhancers(applyMiddleware(ReduxThunk))
);
