import React, { useContext } from "react";
import styled from "react-emotion";
import { Query } from "react-apollo";
import { refreshToken } from "../../actions";
import { GET_STATIONS, SUBSCRIBE_STATION } from "../../api";
import { HomeDiv } from "../shell/Shell";
import Header from "../Header";
import CardView from "../CardView";
import { Shade, ContainerFluid } from "../shell/Shell";
import Navigation from "../shell/Navigation";
import { routes } from "../../App";
import { ContextAuth } from "../../reducers/AuthContext";
import { ContextDeviceProvider, ContextDevice } from "../../reducers/DeviceContext";
import { ContextFileProvider } from "../../reducers/FileContext";

export const LoggerContainer = styled("div")({
  display: "flex",
  flexBasis: "0",
  flexDirection: "row",
  flexWrap: "wrap",
  flexFlow: "row wrap",
  margin: "15px",
  max: "240px"
});

export const Tablo = styled("table")({
  // width: '100%',
  "> thead tr": {
    backgroundColor: "#343a40",
    color: "#FFF",
    padding: "20px",
    textTransform: "uppercase"
  },
  "> tbody tr:nth-child(even)": {
    backgroundColor: "#eee",
    color: "#000"
  },
  "> tbody tr:nth-child(odd)": {
    backgroundColor: "#fff",
    color: "#000"
  },
  " tr > td": {
    padding: "15px 15px"
  }
});

export function Loggers(props) {
  const { state: stateAuth } = useContext(ContextAuth);
  const { stateDevice } = useContext(ContextDevice);
  // const stateDevice = {};
  const { render: Component } = props;
  const { showAll = true } = stateDevice;
  const { user } = stateAuth;
  const entity_id = user && user.entity_id ? user.entity_id : undefined;
  return (
    
      <Shade className="App">
        <ContainerFluid>
          <Navigation routes={routes} />
          <HomeDiv className="home">
            <ContextFileProvider>
              <Header atLeastOneSelected />
            </ContextFileProvider>
            <Query
              query={GET_STATIONS}
              variables={{
                bot_entity_id: showAll ? "0" : entity_id,
                top_entity_id: showAll ? "9223372036854775807" : entity_id
              }}
            >
              {({ data, loading, error, subscribeToMore }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                return Component({
                  ...props,
                  addLogger: async e => {
                    await refreshToken();
                    console.log("adding Logger!");
                  },
                  data,
                  subscribeToStationUpdates: () =>
                    // something is broken that prevents a where clause to work correctly if
                    // a paramaterized query is sent.  hence the replace functions below:
                    subscribeToMore({
                      document: SUBSCRIBE_STATION(query => {
                        return query
                          .replace(
                            showAll ? /\(where\: \{entity_id\: "\$entity_id"\}\)/ : /\$entity_id/,
                            showAll ? "" : entity_id
                          )
                      }),
                      variables: {},
                      updateQuery: (prev, { subscriptionData }) => {
                        if (!subscriptionData.data) return prev;
                        const newFeedItem =
                          subscriptionData.data.station_changed;
                        if (newFeedItem.entity_id !== entity_id) {
                          console.log("don't match:", newFeedItem)
                          return prev;
                        }
                        const index = prev.Stations.findIndex(
                          st => st.id === newFeedItem.id
                        );
                        return {
                          Stations:
                            index < 0
                              ? [...prev.Stations, newFeedItem]
                              : index === 0
                              ? [
                                  newFeedItem,
                                  ...prev.Stations.slice(
                                    index + 1,
                                    prev.Stations.length
                                  )
                                ]
                              : index === prev.Stations.length - 1
                              ? [...prev.Stations.slice(0, index), newFeedItem]
                              : [
                                  ...prev.Stations.slice(0, index),
                                  newFeedItem,
                                  ...prev.Stations.slice(
                                    index + 1,
                                    prev.Stations.length
                                  )
                                ]
                        };
                      }
                    })
                });
              }}
            </Query>
          </HomeDiv>
        </ContainerFluid>
      </Shade>
    // </ContextDeviceProvider>
  );
}

function Home(props) {
  return <ContextDeviceProvider>
    <Loggers render={prps => <CardView {...prps} />} />
  </ContextDeviceProvider>;
}

export default Home;
