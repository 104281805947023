/* eslint-disable no-undef */

import React, { useEffect } from "react";
import { compose, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { Mutation } from "react-apollo";
import { UPDATE_STATION } from "../api";

const csiLatLong = { lat: 41.7659, lng: -111.8548 };
// const centerLatLong = { lat: 50, lng: 0 }
const centerLatLong = csiLatLong;

export const GoogleMaps = compose(
  withStateHandlers(
    () => ({
      isMarkerShown: false,
      markerPosition: null,
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBurpSqpfPqM5n-in3wrCb1NQqH4s-Vzfs&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `100%` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    {}
  ),
  withScriptjs,
  withGoogleMap
)(props => <GoogleMappy {...props} />);

function GoogleMappy(props) {
  useEffect(() => {
    props.subscribeToStationUpdates();
  }, []);
  console.log("props", props);
  const { lat, lng } = props;
  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={
        props.lat
          ? {
              lat: typeof lat === "string" ? parseFloat(lat) : lat,
              lng: typeof lng === "string" ? parseFloat(lng) : lng
            }
          : centerLatLong
      }
      options={{ draggable: true }}
      onClick={props.onMapClick}
    >
      {props.data.Stations.map(station => (
        <Mutation key={station.id} mutation={UPDATE_STATION}>
          {(updateStation, { data }) => (
            <MarkerWithLabel
              position={
                station.data.location && station.data.location.latLong
                  ? station.data.location.latLong
                  : csiLatLong
              }
              labelAnchor={new google.maps.Point(-20, 80)}
              draggable={true}
              onClick={event => {
                event.preventDefault && event.preventDefault();
                console.log("marker was clicked", event);
              }}
              onDragEnd={event => {
                updateStation({
                  variables: {
                    ...station,
                    customer_id: station.entity_id,
                    data: {
                      location: {
                        ...station.data.location,
                        latLong: event.latLng
                      }
                    }
                  }
                });
              }}
            >
              {props.render(station)}
            </MarkerWithLabel>
          )}
        </Mutation>
      ))}
      {props.isMarkerShown && <Marker position={csiLatLong} />}
    </GoogleMap>
  );
}
