import React, { Component } from "react";
import { Button } from "../shell/Button";
import { TextBox } from "../shell/Input";
import { Box, Header } from "../shell/Shell";
import styled from "react-emotion";
import { textSignUp } from "../../actions";

export const CenteredSignUp = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh"
});

export default class SignUp extends Component {
  state = {
    email: "",
    prevEmail: "",
    showSubscribed: false
  };

  emailUpdate = e => {
    // console.log(e.target.value);
    if (e.keyCode === 13) {
      return this.onClickOnSignUp();
    }
    const email = e.target ? e.target.value : "";
    this.setState(state => {
      return { email };
    });
  };

  onClickOnSignUp = async () => {
    await textSignUp(this.state.email);
    this.setState(state => {
      return { email: "", prevEmail: this.state.email, showSubscribed: true };
    });
  };

  render() {
    // console.log(this.state.email);
    const { showSubscribed, email, prevEmail } = this.state;
    return (
      <CenteredSignUp className="signup">
        <Box>
          <Header>Subscribe</Header>
          {showSubscribed ? (
            <p style={{ color: "#fff" }}>{prevEmail} has been subscribed.</p>
          ) : (
            "Sign up for text messages when a logger goes down."
          )}
          <TextBox
            onChange={this.emailUpdate}
            placeholder="+14353334444"
            value={email}
          />
          <div style={{ width: "50%" }}>
            <Button style={{ margin: "0px" }} onClick={this.onClickOnSignUp}>
              Sign Up
            </Button>
          </div>
        </Box>
      </CenteredSignUp>
    );
  }
}
