import * as React from "react";
let ContextFile = React.createContext();

const initialState = {
  files: [],
  oses: [],
  selectedFile: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILES":
      return { ...state, files: action.payload };
    case "SET_OSES":
      return { ...state, oses: action.payload };
    case "SET_SELECTED_FILE":
      return { ...state, selectedFile: action.payload };
    default:
      return state;
  }
};

function ContextFileProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { stateFile: state, dispatchFile: dispatch };
  return (
    <ContextFile.Provider value={value}>{props.children}</ContextFile.Provider>
  );
}

let ContextFileConsumer = ContextFile.Consumer;

export { ContextFile, ContextFileProvider, ContextFileConsumer };

export default reducer;
