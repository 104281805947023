import React, { useState, Fragment } from "react";
import { Link } from "@reach/router";
import styled from "react-emotion";
import { keyframes } from "react-emotion";
import { Button, LinkButton } from "./shell/Button";
import Popover from "react-popover";
import { getOnBoardToken } from "../actions";
import { ReactComponent as ThreeDots } from "../icons/three-dots.svg";
import { ReactComponent as Left } from "../icons/SVG/LEFT.svg";
import { ReactComponent as Right } from "../icons/SVG/RIGHT.svg";

const breakpoints = [576, 768, 992, 1200];

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

export const blackLogger =
  "linear-gradient(-25deg, #222, #222, black, black, black, black)";

export const errorLogger =
  "linear-gradient(-25deg,  rgba(160,0,0,1), rgba(160,0,0,1), rgba(100,0,0,1), rgba(100,0,0,1), rgba(100,0,0,1), rgba(100,0,0,1),  rgba(100,0,0,1))";

const addLogger =
  "linear-gradient(-25deg, rgba(0,0,0,.10), rgba(0,0,0,.10), rgba(0,0,0,.15), rgba(0,0,0,.15), rgba(0,0,0,.15), rgba(0,0,0,.15), rgba(0,0,0,.15))";

const baselineheight = 24,
  // white = "rgb(255,255,255)",
  offWhite = "rgba(255,255,255,0.2)",
  pulseDuration = 750;

const pulse = keyframes`
  50% { background: #fff }
`;

const svgStyle = {
  height: 145,
  margin: "15px 0 0 0"
};

export const LoadingAnimation = styled("div")({
  position: "relative",
  width: `${baselineheight / 4}px`,
  height: `${baselineheight}px`,
  background: offWhite,
  animation: `${pulse} ${pulseDuration}ms infinite`,
  animationDelay: `${pulseDuration / 3}ms`,
  ":before": {
    content: `''`,
    position: "absolute",
    display: "block",
    height: `${baselineheight / 1.5}px`,
    width: `${baselineheight / 4}px`,
    background: offWhite,
    top: "50%",
    transform: "translateY(-50%)",
    animation: `${pulse} ${pulseDuration}ms infinite`,
    left: `${-(baselineheight / 2)}px`
  },
  ":after": {
    content: `''`,
    position: "absolute",
    display: "block",
    height: `${baselineheight / 1.5}px`,
    width: `${baselineheight / 4}px`,
    background: offWhite,
    top: "50%",
    transform: "translateY(-50%)",
    animation: `${pulse} ${pulseDuration}ms infinite`,
    left: `${baselineheight / 2}px`,
    animationDelay: `${pulseDuration / 1.5}ms`
  }
});

export const LoadingText = styled("div")({
  marginLeft: "30px"
});

export const LoggerStyled = styled("div")(props => ({
  flex: "1 1 0",
  margin: props.margin,
  padding: "30px",
  color: "#ccc",
  position: "relative",
  cursor: "pointer",
  transition: "height 200ms",
  minWidth: "calc(100% - 83px)",
  maxWidth: "calc(100% - 83px)",
  [mq[0]]: {
    minWidth: "calc(50% - 83px)",
    maxWidth: "calc(50% - 83px)"
  },
  [mq[1]]: {
    minWidth: "calc(33.333333% - 83px)",
    maxWidth: "calc(33.333333% - 83px)"
  },
  [mq[2]]: {
    minWidth: "calc(25% - 83px)",
    maxWidth: "calc(25% - 83px)"
  },
  [mq[3]]: {
    minWidth: "calc(20% - 83px)",
    maxWidth: "calc(20% - 83px)"
  },
  background: props.background,
  boxShadow: props.boxshadow,
  height: props.height,
  borderTop: props.borderTop,
  marginTop: props.marginTop
}));

const LoggerAddStyle = styled(LoggerStyled)(props => ({
  color: "#FFF",
  fontSize: "2rem",
  fontWeight: 800,
  fontFamily: "poppinsmedium",
  cursor: "pointer",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginLeft: 15
}));

const H1 = styled("h1")({
  fontWeight: "800",
  fontFamily: "poppinsmedium",
  width: "100%",
  fontSize: "3.6rem"
});

const Head = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "left",
  width: "100%",
  justifyContent: "left",
  height: "fit-content"
});

const HeaderRight = styled("span")({
  fontSize: "1.5rem",
  marginTop: "0px"
});

export const Tag = styled(Head)(props => ({
  fontSize: "1.5rem",
  fontWeight: "200",
  fontFamily: "poppinslight",
  color: !props.connected ? "rgba(160,80,80,1)" : "#666",
  marginTop: "15px",
  marginBottom: "-.9rem"
}));

const PopoverBody = styled("div")({
  display: "flex",
  flexDirection: "column"
});

const LoggerButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  color: "#739dbb",
  width: "auto",
  border: "none"
});

export default function Logger(props) {
  const [popoverActive, setPopoverActive] = useState(false);
  let { device, head, headRight, selected } = props;
  let lat = "",
    lng = "",
    connected = true,
    background = blackLogger,
    boxshadow = "none",
    height = "85px",
    borderTop = "none",
    marginTop = "15px";

  const selectedToggle = () => {
    // console.log("selectedToggle:", device.id);
    props.selectedToggle(device.id);
  };

  const togglePopoverLogger = (e, toState) => {
    e.stopPropagation();
    const popoverIsOpen =
      typeof toState === "boolean" ? toState : !popoverActive;
    setPopoverActive(popoverIsOpen);
  };

  let popoverProps = {
    preferPlace: "below",
    place: "below",
    body: [<h1 key="a">Popover Title</h1>, <div key="b">Popover contents</div>]
  };

  // useEffect(
  //   () => {

  //   },
  //   [device.data.location.latLong.lat]
  // );

  lat =
    device &&
    device.data &&
    device.data.location &&
    device.data.location.latLong &&
    device.data.location.latLong.lat
      ? device.data.location.latLong.lat
      : "";

  lng =
    device &&
    device.data &&
    device.data.location &&
    device.data.location.latLong &&
    device.data.location.latLong.lng
      ? device.data.location.latLong.lng
      : "";
  connected = device && device.status === "online";
  background = connected ? blackLogger : errorLogger;
  boxshadow = props.selected ? "5px 5px 20px 5px #739dbb" : "none";
  height = props.selected ? "140px" : "85px";
  borderTop = props.selected ? "solid 5px #417bbf" : "none";
  marginTop = props.selected ? "10px" : "15px";

  const {
    OS_Version = "Not Reported",
    Program_Name = "Not Reported",
    Skipped_Scan = "Not Reported",
    Watchdog_Errors = "Not Reported"
  } =
    device && device.data && device.data.shadow
      ? device.data.shadow.reported
      : {};

  return (
    <Fragment>
      {!selected && <Left style={svgStyle} />}
      <LoggerStyled
        className="Logger"
        onClick={selectedToggle}
        background={background}
        boxshadow={boxshadow}
        height={height}
        borderTop={borderTop}
        marginTop={marginTop}
        margin={selected ? "30px 11px 30px 11px" : "30px 0px"}
      >
        <Head css={{ color: props.selected ? "#FFF" : "#CCC" }}>
          <H1>{head}</H1>
          <HeaderRight>{headRight}</HeaderRight>
          <Popover
            style={{ padding: ".5rem 1rem" }}
            {...popoverProps}
            isOpen={popoverActive}
            onOuterAction={togglePopoverLogger}
            body={
              <PopoverBody>
                <LinkButton>
                  <Link
                    to={`/data/${device && device.datalogger_id}`}
                    style={{ color: "#FFF", textDecoration: "none" }}
                  >
                    View Data
                  </Link>
                </LinkButton>
                <LinkButton>
                  <Link
                    to={`/map/${lat}/${lng}`}
                    style={{ color: "#FFF", textDecoration: "none" }}
                  >
                    View Location
                  </Link>
                </LinkButton>
              </PopoverBody>
            }
          >
            <LoggerButton onClick={togglePopoverLogger}>
              <ThreeDots style={{ maxWidth: "1.2rem", maxHeight: "1.2rem" }} />
            </LoggerButton>
          </Popover>
        </Head>
        {selected && OS_Version && (
          <Tag connected={connected}>{OS_Version}</Tag>
        )}
        {selected && Program_Name && (
          <Tag connected={connected}>{Program_Name} is running</Tag>
        )}
        {selected && Skipped_Scan !== undefined && (
          <Tag connected={connected}>{Skipped_Scan} skipped scans</Tag>
        )}
        {selected && Watchdog_Errors !== undefined && (
          <Tag connected={connected}>{Watchdog_Errors} errors</Tag>
        )}
        {device &&
          device.file_transfer_status &&
          device.file_transfer_status !== "idle" && (
            <Tag connected={connected}>
              <LoadingAnimation />
              <LoadingText css={{ fontSize: ".7rem" }}>
                {device && device.file_transfer_status
                  ? device.file_transfer_status
                  : "Uploading"}
              </LoadingText>
            </Tag>
          )}
      </LoggerStyled>
      {!selected && <Right style={{ ...svgStyle }} />}
    </Fragment>
  );
}

export function AddLoggerComponent() {
  const [selected, setSelected] = useState(false);

  const boxshadow = selected ? "5px 5px 20px 5px #739dbb" : "none";
  const height = selected ? "140px" : "85px";
  const borderTop = selected ? "solid 5px #417bbf" : "none";
  const marginTop = selected ? "10px" : "15px";

  // const onClickHandler = s => {
  //   if (s) {
  //     // onboard the SOB
  //   }
  // };

  async function getOnBoardTokenComponent(e) {
    e.stopPropagation();
    await getOnBoardToken();
    setSelected(false);
  }

  return (
    <LoggerAddStyle
      className="Logger"
      background={selected ? "#417bbf" : addLogger}
      boxshadow={boxshadow}
      height={height}
      borderTop={borderTop}
      marginTop={marginTop}
      onClick={e => setSelected(!selected)}
    >
      <H1>+ Add</H1>
      {selected && (
        <React.Fragment>
          <ul
            style={{
              fontSize: "1.5rem",
              fontFamily: "poppinslight",
              fontWeight: 200,
              margin: "15px 0"
            }}
          >
            <li>Send file using Dev Config, then powercycle</li>
            <li>
              Once complete, the datalogger should connect to Campbell Cloud
            </li>
          </ul>
          <Button onClick={getOnBoardTokenComponent}>Download</Button>
        </React.Fragment>
      )}
    </LoggerAddStyle>
  );
}
