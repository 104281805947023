import React, { useEffect, useState } from "react";
import styled from "react-emotion";
import { Query, Mutation } from "react-apollo";
import { Button, LinkButton } from "../shell/Button";
import { GET_SETTING, UPDATE_SETTING } from "../../api";
import { uploadOs, uploadCrBasic } from "../../actions";
import { HomeDiv } from "../shell/Shell";
import { Shade, ContainerFluid } from "../shell/Shell";
import Navigation from "../shell/Navigation";
import { routes } from "../../App";
import { ReactComponent as Ex } from "../../icons/cancel.svg";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
const shortid = require("shortid");

export const LoggerTypesContainer = styled("div")({
  display: "flex",
  flexBasis: "0",
  flexDirection: "row",
  flexWrap: "wrap",
  flexFlow: "row wrap",
  margin: "15px",
  width: "calc(100% - 30px)",
  flex: "50%"
});

const LoggerTypeContainer = styled("div")({
  display: "block",
  margin: "15px",
  width: "calc(50% - 30px)"
});

const HiddenFileInput = styled("input")({
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  opacity: 0
});

const CurrentTag = styled("div")({
  display: "flex",
  fontSize: "1.2rem",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  backgroundColor: "#AAA",
  color: "#FFF",
  borderRadius: 25,
  padding: 5,
  right: 50,
  width: 60,
  height: 15
});

const OsName = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "calc( 100% - 110px)"
});

const OperatingSystem = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",
  padding: "15px",
  background: "linear-gradient(-25deg, #DDD, #DDD, #EEE, #EEE, #EEE, #EEE)",
  width: "calc(100% - 30px)"
});

const Header1 = styled("h1")({
  fontSize: "3.6rem",
  width: "100%"
});
// const Header2 = styled("h2")({
//   fontSize: "2.8rem",
//   width: "100%"
// });
const Header3 = styled("h3")({
  fontSize: "2.4rem",
  width: "100%"
});

function Admin(props) {
  return (
    <Shade className="App">
      <ContainerFluid>
        <Navigation routes={routes} />
        <HomeDiv className="home">
          <div style={{ alignItems: "center", padding: "7% 20%" }}>
            <div style={{ alignItems: "center" }}>
              <AdminData
                type="system"
                sub_type="general"
                sub_sub_type="general"
              >
                {prps => (
                  <Mutation mutation={UPDATE_SETTING}>
                    {(updateSetting, { data: nextData }) => (
                      <React.Fragment>
                        {prps.loading && <div>Loading...</div>}
                        {prps.error && <div>Error!</div>}
                        {!prps.loading && !prps.error && (
                          <OSDisplay
                            {...prps}
                            updateSetting={updateSetting}
                            nextData={nextData}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Mutation>
                )}
              </AdminData>
              <AdminData
                type="system"
                sub_type="programs"
                sub_sub_type="crbasic"
              >
                {prps => (
                  <Mutation mutation={UPDATE_SETTING}>
                    {(updateSetting, { data: nextData }) => (
                      <React.Fragment>
                        {prps.loading && <div>Loading...</div>}
                        {prps.error && <div>Error!</div>}
                        {!prps.loading && !prps.error && (
                          <CrbasicDisplay
                            {...prps}
                            updateSetting={updateSetting}
                            nextData={nextData}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Mutation>
                )}
              </AdminData>
            </div>
          </div>
        </HomeDiv>
      </ContainerFluid>
    </Shade>
  );
}


function OSDisplay(props) {
  // const { state: stateAuth } = useContext(ContextAuth);
  // const stateDevice = {};
  const { updateSetting} = props;
  // const { user } = stateAuth;
  const [setting, setSetting] = useState({});
  const defaultData = {
    deviceTypes: {
      CR1000X: {},
      CR300: {}
    },
    operatingSystems: {
      CR1000X: {
        current: "AJEI3452346SSDF5",
        history: [
          {
            id: "AJEI3452346SSDF5",
            name: "Recent OS",
            bucketLocation: "/asdf/asdf/asdf"
          }
        ]
      }
    }
  };

  const { deviceTypes = {}, operatingSystems = {} } = setting;
  // const entity_id = user && user.entity_id ? user.entity_id : undefined;

  useEffect(() => {
    setSetting(props.setting.data ? props.setting.data : defaultData);
  }, [props.setting]);

  const saveSetting = newSetting => {
    try {
      updateSetting({
        variables: {
          ...props.setting,
          data: newSetting ? newSetting : setting,
          id: props.setting.setting_id
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addOsData = ({ type, Os }) => {
    const newSetting = {
      ...setting,
      operatingSystems: {
        ...setting.operatingSystems,
        [type]: {
          current: Os.id,
          history: [
            Os,
            ...(setting.operatingSystems[type] &&
            setting.operatingSystems[type].history
              ? setting.operatingSystems[type].history
              : [])
          ]
        }
      }
    };

    saveSetting(newSetting);
    setSetting(newSetting);
  };

  const deleteOsData = ({ id, type }) => {
    const history = [
      ...(setting.operatingSystems[type] &&
      setting.operatingSystems[type].history
        ? setting.operatingSystems[type].history.filter(o => o.id !== id)
        : [])
    ];
    const newSetting = {
      ...setting,
      operatingSystems: {
        ...setting.operatingSystems,
        [type]: {
          current:
            setting.operatingSystems[type].current === id
              ? history.length > 0
                ? history[0].id
                : undefined
              : setting.operatingSystems[type].current,
          history
        }
      }
    };

    saveSetting(newSetting);
    setSetting(newSetting);
  };


  const uploadFile = (e, type) => {
    uploadOs({
      file: e.target.files[0],
      type,
      onComplete: data => {
        console.log("uploadFile:", data);
        if (type !== "None") {
          const id = shortid.generate();
          const tempOs = {
            id,
            name: `${type} ${new Date().toLocaleDateString()}`,
            Key: data.data.Key,
            Bucket: data.data.Bucket
          };
          console.log("tempOs:", tempOs);
          addOsData({ Os: tempOs, type });
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Header1>Operating Systems</Header1>
      <LoggerTypesContainer>
        {Object.keys(deviceTypes).map(type => (
          <LoggerType
            key={type + "_TYPE"}
            {...operatingSystems[type]}
            addData={addOsData}
            deleteData={deleteOsData}
            uploadFile={uploadFile}
            filetypes=".obj,.zip"
            type={type}
          />
        ))}
        {/* <button onClick={saveSetting}>Save</button> */}
    </LoggerTypesContainer>
    </React.Fragment>
  );
}

function CrbasicDisplay(props) {
  // const { state: stateAuth } = useContext(ContextAuth);
  // const stateDevice = {};
  const { updateSetting, loading, error } = props;
  // const { user } = stateAuth;
  const [setting, setSetting] = useState({});
  const defaultData = {
    deviceTypes: {
      CR1000X: {},
      CR300: {}
    },
    crbasic: {
      CR1000X: {
        current: "AJEI3452346SSDF5",
        history: [
          {
            id: "AJEI3452346SSDF5",
            name: "Recent CRBasic",
            bucketLocation: "/asdf/asdf/asdf"
          }
        ]
      }
    }
  };

  const { deviceTypes = {}, crbasic = {} } = setting;
  // const entity_id = user && user.entity_id ? user.entity_id : undefined;

  useEffect(() => {
    setSetting(props.setting.data ? props.setting.data : defaultData);
  }, [props.setting]);

  const saveSetting = newSetting => {
    try {
      updateSetting({
        variables: {
          ...props.setting,
          data: newSetting ? newSetting : setting,
          id: props.setting.setting_id
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addCrBasicData = ({ type, Crbasic }) => {
    const newSetting = {
      ...setting,
      crbasic: {
        ...setting.crbasic,
        [type]: {
          current: Crbasic.id,
          history: [
            Crbasic,
            ...(setting.crbasic[type] &&
            setting.crbasic[type].history
              ? setting.crbasic[type].history
              : [])
          ]
        }
      }
    };

    saveSetting(newSetting);
    setSetting(newSetting);
  };

  const deleteCrbasicData = ({ id, type }) => {
    const history = [
      ...(setting.crbasic[type] &&
      setting.crbasic[type].history
        ? setting.crbasic[type].history.filter(o => o.id !== id)
        : [])
    ];
    const newSetting = {
      ...setting,
      crbasic: {
        ...setting.crbasic,
        [type]: {
          current:
            setting.crbasic[type].current === id
              ? history.length > 0
                ? history[0].id
                : undefined
              : setting.crbasic[type].current,
          history
        }
      }
    };

    saveSetting(newSetting);
    setSetting(newSetting);
  };


  const uploadFile = (e, type) => {
    uploadCrBasic({
      file: e.target.files[0],
      type,
      onComplete: data => {
        console.log("uploadFile:", data);
        if (type !== "None") {
          const id = shortid.generate();
          const tempCrbasic = {
            id,
            name: `${type} ${new Date().toLocaleDateString()}`,
            Key: data.data.Key,
            Bucket: data.data.Bucket
          };
          console.log("tempCrBasic:", tempCrbasic);
          addCrBasicData({ Crbasic: tempCrbasic, type });
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Header1 style={{marginTop: 30}}>CRBasic Programs</Header1>
      <LoggerTypesContainer>
        {Object.keys(deviceTypes).map(type => (
          <LoggerType
            key={type + "_TYPE"}
            {...crbasic[type]}
            addData={addCrBasicData}
            deleteData={deleteCrbasicData}
            uploadFile={uploadFile}
            filetypes=".cr1,.dld,.cr1x,.crb"
            type={type}
          />
        ))}
        {/* <button onClick={saveSetting}>Save</button> */}
      </LoggerTypesContainer>
    </React.Fragment>
  );
}


function LoggerType(props) {
  const { current = "", history = [], type = "None", uploadFile, filetypes } = props;
  const file = history.find(c => c.id === current);


  const deleteData = id => {
    if (type !== "None") {
      props.deleteData({ id, type });
    }
  };

  return (
    <LoggerTypeContainer>
      <Header3>{type}</Header3>
      {file && file.name ? (
        <OperatingSystem style={{ marginBottom: 20 }} title={file.Key}>
          <OsName>{file && file.Key}</OsName>
          <CurrentTag>CURRENT</CurrentTag>
          <LinkButton
            style={{
              position: "absolute",
              height: "100%",
              width: "55px",
              right: 0
            }}
            onClick={() => deleteData(file.id)}
          >
            <Ex style={{ height: 20, width: 20 }} />
          </LinkButton>
        </OperatingSystem>
      ) : (
        "None"
      )}
      {history.length > 0 && <div>History</div>}
      {history.map(h => (
        <OperatingSystem key={h.id + "_OS"} title={h.Key}>
          <OsName>{h && h.Key ? h.Key : "None"}</OsName>
          <LinkButton
            style={{
              position: "absolute",
              height: "100%",
              width: "55px",
              right: 0
            }}
            onClick={() => deleteData(h.id)}
          >
            <Ex style={{ height: 20, width: 20 }} />
          </LinkButton>
        </OperatingSystem>
      ))}
      <Button
        style={{
          backgroundColor: "#333",
          marginTop: "15px",
          position: "relative"
        }}
      >
        <UploadIcon style={{ width: "1.3rem", height: "1.3rem" }} /> &nbsp;{" "}
        {type} OS
        <HiddenFileInput
          type="file"
          onChange={e => uploadFile(e, type)}
          id={`upload-${type}`}
          name={`upload-${type}`}
          accept={filetypes}
        />
      </Button>
    </LoggerTypeContainer>
  );
}

export function AdminData(props) {
  return (
    <Query
      query={GET_SETTING}
      variables={{
        type: props.type,
        sub_type: props.sub_type,
        sub_sub_type: props.sub_sub_type
      }}
    >
      {({ data, loading, error }) => {
        const setting =
          data && data.Settings && data.Settings.length > 0
            ? data.Settings[0]
            : {};
        return props.children({ ...props, setting, loading, error });
      }}
    </Query>
  );
}


export function AdminUgly(props) {
  const { setting, updateSetting } = props;
  const [settingTemp, setSettingTemp] = useState({});
  useEffect(() => {
    setSettingTemp(JSON.stringify(setting, null, "\t"));
  }, [props.setting]);
  return (
    <Shade className="App">
      <ContainerFluid>
        <Navigation routes={routes} />
        <HomeDiv className="home">
          <textarea
            onChange={e => {
              setSettingTemp(e.target.value);
            }}
            style={{ width: 500, height: 500 }}
            value={settingTemp}
          />
          <button
            onClick={() => {
              try {
                let tmp = JSON.parse(settingTemp);
                updateSetting({
                  variables: {
                    ...tmp,
                    id: tmp.setting_id
                  }
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Save
          </button>
        </HomeDiv>
      </ContainerFluid>
    </Shade>
  );
}
export default Admin;
