import React, { useState, useContext, useEffect } from "react";
import styled from "react-emotion";
import { Button, LinkButton } from "../shell/Button";
import { Box, Header } from "../shell/Shell";
import { Link } from "@reach/router";
import { authFunctions } from "../../constants";
import { login, autoLogin, validatetoken, updatePassword } from "../../actions";
import Input from "../shell/Input";
import { ContextAuth } from "../../reducers/AuthContext";

export const CenteredLogin = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh"
});

function Login(props) {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [changePass, setChangePass] = useState(false);
  const [refreshToken, setRefreshToken] = useState("");
  const { state, dispatch } = useContext(ContextAuth);

  useEffect(() => {
    if (!state.user) {
      firstLoad();
    }
  }, [state.user]);

  async function firstLoad() {
    // console.log("firstLoad user: ", state.user);
    const { func, token } = props;
    // console.log(route);
    let payload;
    // console.log(route, func, token);
    switch (func) {
      case authFunctions.autoLogin:
        // auto login
        console.log("autoLogin");
        payload = await autoLogin({ token });
        if (payload.auth) dispatch({ type: "SET_AUTH", payload });
        break;
      case authFunctions.passwordReset:
        // reset password
        console.log("reset password");
        setChangePass(true);
        setRefreshToken(token);
        break;
      default:
        payload = await validatetoken();
        // console.log("validate token", payload);
        if (payload.auth) dispatch({ type: "SET_AUTH", payload });
        break;
    }
    console.log(`func: ${func}, token: ${token}`);
  }
  return (
    <CenteredLogin>
      <Box>
        <Header>{changePass ? "Change Password" : "Login"}</Header>
        <Input
          type="text"
          label="Username"
          value={Username}
          onChange={e => setUsername(e.target.value)}
        />
        <Input
          type="password"
          label="Password"
          value={Password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          marginBottom="15px"
          marginTop="20px"
          onClick={async () => {
            if (changePass) {
              const upd = await updatePassword({
                Username,
                Password,
                changePass,
                token: refreshToken
              });
              console.log("upd", upd);
            } else {
              let payload = await login({
                Username,
                Password,
                changePass,
                token: refreshToken
              });
              // console.log("user", payload);
              if (payload.auth) dispatch({ type: "SET_AUTH", payload });
            }
          }}
        >
          {changePass ? "Update" : "Login"}
        </Button>

        {!changePass && (
          <Link to={`/forgotpassword`}>
            <LinkButton>Forgot Password?</LinkButton>
          </Link>
        )}
      </Box>
    </CenteredLogin>
  );
}

export default Login;
