import React from "react";
import styled from "react-emotion";
import { Tag, LoadingAnimation, LoadingText, blackLogger } from "./Logger";

export const MapLogger = styled("div")({
  position: "relative",
  height: "2.5rem",
  boxShadow: "none",
  minWidth: "100px",
  minHeight: "1.5rem",
  padding: "5px",
  background: blackLogger,
  borderTop: "none",
  "&::after": {
    right: "100%",
    top: "50%",
    border: "solid transparent",
    content: " ",
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    borderColor: "rgba(136, 183, 213, 0)",
    borderRightColor: "#88b7d5",
    borderWidth: "10px",
    marginTop: "-10px"
  }
});

const LoggerTitle = styled("span")({
  fontWeight: 800,
  fontFamily: "poppinsmedium",
  width: "100%",
  fontSize: "1.1rem"
});

export default function MapMarkerCard(props) {
  let device = props.device ? props.device : { data: { location: {} } };
  const selected = false;
  const connected = device.status === "online";
  return (
    <MapLogger>
      <LoggerTitle style={{ color: selected ? "white" : "#ccc" }}>
        {device && device.name ? device.name : ""}
      </LoggerTitle>
      <span style={{ color: selected ? "white" : "#ccc" }}>
        {device.location_name ? device.location_name : ""}
      </span>
      {selected && (
        <Tag
          css={{
            color: !connected ? "rgba(160,80,80,1)" : selected ? "#FFF" : "#999"
          }}
        >
          {device.thingTypeName}
        </Tag>
      )}
      {selected && (
        <Tag
          css={{
            color: !connected
              ? "rgba(160,80,80,1)"
              : selected
              ? "#FFF"
              : "#999",
            marginBottom: "30px"
          }}
        >
          {device.Program_Name}
        </Tag>
      )}
      {device.file_transfer_status && device.file_transfer_status !== "idle" ? (
        <Tag
          css={{
            color: "#bbb",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px"
          }}
        >
          <LoadingAnimation />
          <LoadingText>
            {device.file_transfer_status
              ? device.file_transfer_status
              : "Uploading"}
          </LoadingText>
        </Tag>
      ) : null}
    </MapLogger>
  );
}
