import * as React from "react";
import { authStates } from "../constants";
let ContextAuth = React.createContext();

const initialState = {
  loggedIn: false,
  user: null,
  authState: "",
  token: localStorage.getItem("cs-auth")
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGGED_IN_USER":
      return { ...state, loggedIn: true, user: action.payload };
    case "SET_AUTH":
      const { token, user } = action.payload;
      if (token) localStorage.setItem("cs-auth", token);
      return {
        ...state,
        loggedIn: true,
        authState: authStates.signedIn,
        token: token ? token : localStorage.getItem("cs-auth"),
        user
      };
    case "LOGOUT_USER":
      localStorage.setItem("cs-auth", "");
      return {
        ...state,
        loggedIn: false,
        user: null,
        authState: authStates.loggedOut
      };
    case "SET_AUTH_STATE":
      return { ...state, authState: action.payload };
    case "SET_AUTH_TOKEN":
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

function ContextAuthProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };
  return (
    <ContextAuth.Provider value={value}>{props.children}</ContextAuth.Provider>
  );
}

let ContextAuthConsumer = ContextAuth.Consumer;

export { ContextAuth, ContextAuthProvider, ContextAuthConsumer };

export default reducer;
