import React, { useContext, useEffect, useState } from "react";
import styled from "react-emotion";
import Popover from "react-popover";
import { Mutation } from "react-apollo";
import { Button, DropButton } from "./shell/Button";
import {
  getAllCrBasicPrograms,
  sendCrBasicProgramToSelectedDataloggers,
  sendOsToSelectedDataloggers,
  updateMqttToSelectedDataloggers,
  setFiles,
  setSelectedDevices
} from "../actions";
import { DELETE_STATION } from "../api";
import { ContextDevice } from "../reducers/DeviceContext";
import { ContextFile } from "../reducers/FileContext";
import { ReactComponent as Arrow } from "../icons/right-arrow.svg";
import { ReactComponent as CheckBox } from "../icons/check-square.svg";
import { AdminData } from "./routes/Admin";

export const BarButton = styled(Button)(props => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "unset"
}));

export const PopoverBody = styled("div")({
  display: "flex",
  flexDirection: "column"
});

export const FunctionRow = styled("div")({
  display: "flex",
  padding: "1rem",
  height: "4rem",
  backgroundColor: "#417bbf",
  "& > *:not(:first-child)": {
    marginLeft: "15px"
  }
});

export const ButtonWidth = styled("div")({
  display: "flex",
  minWidth: "100px"
});

export default function FunctionBar(props) {
  const [popoverIsOpenProg, setPopoverIsOpenProg] = useState(false);
  const [popoverIsOpenOs, setPopoverIsOpenOs] = useState(false);
  // const [popoverIsOpenClock, setPopoverIsOpenClock] = useState(false);
  const { stateDevice, dispatchDevice } = useContext(ContextDevice);

  const uploadProg = async (fileName, bucketName) => {
    await sendCrBasicProgramToSelectedDataloggers(
      fileName,
      bucketName,
      stateDevice
    );

    dispatchDevice(setSelectedDevices({}));
    //console.log("upload");
  };

  const uploadOs = async (fileName, bucketName) => {
    await sendOsToSelectedDataloggers(fileName, bucketName, stateDevice);

    dispatchDevice(setSelectedDevices({}));
    //console.log("upload");
  };

  const updateMqtt = async () => {
    await updateMqttToSelectedDataloggers(stateDevice);

    dispatchDevice(setSelectedDevices({}));
    //console.log("upload");
  };

  const togglePopoverProg = toState => {
    const popoverIsOpen =
      typeof toState === "boolean" ? toState : !popoverIsOpenProg;
    // console.log({ current: this.state.popoverIsOpenProg, next: popoverIsOpen });
    setPopoverIsOpenProg(popoverIsOpen);
  };

  const togglePopoverOs = toState => {
    const popoverIsOpen =
      typeof toState === "boolean" ? toState : !popoverIsOpenOs;
    // console.log({ current: this.state.popoverIsOpenOs, next: popoverIsOpen });
    setPopoverIsOpenOs(popoverIsOpen);
  };

  const { selectedDevices } = stateDevice;

  let popoverProps = {
    preferPlace: props.preferPlace,
    place: props.place,
    body: [<h1 key="a">Popover Title</h1>, <div key="b">Popover contents</div>]
  };

  return (
    <FunctionRow>
      <CheckBox
        style={{ maxWidth: "3.2rem", maxHeight: "2.9rem" }}
        alt="checkbox"
      />
      <ButtonWidth>
        <AdminData
          type="system"
          sub_type="programs"
          sub_sub_type="crbasic"
        >
          {AdminData => {
            const files =
              AdminData.setting &&
              AdminData.setting.data &&
              AdminData.setting.data.crbasic &&
              AdminData.setting.data.crbasic.CR1000X
                ? AdminData.setting.data.crbasic.CR1000X.history
                : [];

            // console.log("osFiles", osFiles);
            return (
              <Popover
                {...popoverProps}
                isOpen={popoverIsOpenProg}
                onOuterAction={() => togglePopoverProg(false)}
                body={
                  <PopoverBody>
                    <h1>CR Basic List</h1>
                    {files ? (
                      files.map((file, i) => (
                        <DropButton
                          key={file.id}
                          onClick={() =>
                            uploadProg(file.Key, file.Bucket)
                          }
                        >
                          {file.Key}
                        </DropButton>
                      ))
                    ) : (
                      <div> Loading </div>
                    )}
                  </PopoverBody>
                }
              >
                <BarButton marginLeft="18px" onClick={togglePopoverProg}>
                  Program
                  <Arrow
                    alt="Program SEND"
                    style={{
                      maxHeight: "1rem",
                      display: "inline-block",
                      maxWidth: "2rem"
                    }}
                  />
                </BarButton>
              </Popover>
            );
          }}
        </AdminData>
      </ButtonWidth>
      <ButtonWidth>
        <AdminData
          type="system"
          sub_type="general"
          sub_sub_type="general"
        >
          {AdminData => {
            const osFiles =
              AdminData.setting &&
              AdminData.setting.data &&
              AdminData.setting.data.operatingSystems &&
              AdminData.setting.data.operatingSystems.CR1000X
                ? AdminData.setting.data.operatingSystems.CR1000X.history
                : [];

            // console.log("osFiles", osFiles);
            return (
              <Popover
                {...popoverProps}
                isOpen={popoverIsOpenOs}
                onOuterAction={() => togglePopoverOs(false)}
                body={
                  <PopoverBody>
                    <h1>OS List</h1>
                    {osFiles ? (
                      osFiles.map((file, i) => (
                        <DropButton
                          key={file.id}
                          onClick={() =>
                            uploadOs(file.Key, file.Bucket)
                          }
                        >
                          {file.Key}
                        </DropButton>
                      ))
                    ) : (
                      <div> Loading </div>
                    )}
                  </PopoverBody>
                }
              >
                <BarButton marginLeft="18px" onClick={togglePopoverOs}>
                  OS
                  <Arrow
                    alt="OS SEND"
                    style={{
                      maxHeight: "1rem",
                      display: "inline-block",
                      maxWidth: "2rem"
                    }}
                  />
                </BarButton>
              </Popover>
            );
          }}
        </AdminData>
      </ButtonWidth>
      <ButtonWidth>
        <BarButton marginLeft="18px" onClick={updateMqtt}>
          Certs
          <Arrow
            alt="Renew Certs"
            style={{
              maxHeight: "1rem",
              display: "inline-block",
              maxWidth: "2rem"
            }}
          />
        </BarButton>
      </ButtonWidth>
      <ButtonWidth>
        <Mutation mutation={DELETE_STATION}>
          {(deleteStation, { data }) => (
            <BarButton
              marginLeft="18px"
              onClick={e => {
                Object.keys(selectedDevices).map(id => {
                  if (selectedDevices[id]) {
                    // console.log(selectedDevices[id]);
                    const { datalogger_id, station_id } = selectedDevices[id];
                    deleteStation({
                      variables: {
                        station_id,
                        datalogger_id
                      }
                    });
                  }
                  return selectedDevices[id];
                });
              }}
            >
              Delete
              <Arrow
                alt="X"
                style={{
                  maxHeight: "1rem",
                  display: "inline-block",
                  maxWidth: "2rem"
                }}
              />
            </BarButton>
          )}
        </Mutation>
      </ButtonWidth>
      {/* <ButtonWidth>
        <BarButton marginLeft="18px" onClick={getServices}>
          Services
          <Arrow
            alt="Services"
            style={{
              maxHeight: "1rem",
              display: "inline-block",
              maxWidth: "2rem"
            }}
          />
        </BarButton>
      </ButtonWidth> */}
    </FunctionRow>
  );
}
