import React, { useEffect, useContext } from "react";
import Logger, { AddLoggerComponent } from "./Logger";
import styled from "react-emotion";
import { setSelectedDevices } from "../actions";
import { ContextDevice } from "../reducers/DeviceContext";

export const LoggerContainer = styled("div")({
  display: "flex",
  flexBasis: "0",
  flexDirection: "row",
  flexWrap: "wrap",
  flexFlow: "row wrap",
  margin: "15px",
  max: "240px"
});

export default function CardView(props) {
  const { data } = props;
  const { stateDevice: state, dispatchDevice: dispatch } = useContext(
    ContextDevice
  );

  const selectedToggle = id => {
    const device = data.Stations.find(s => s.id === id);
    // console.log("device: ", device);
    const { selectedDevices } = state;
    const theValue = state.selectedDevices[id];
    dispatch(
      setSelectedDevices({
        ...selectedDevices,
        [id]: theValue === undefined ? { ...device } : undefined
      })
    );
  };

  useEffect(() => {
    props.subscribeToStationUpdates();
    // console.log("subscribed to more updates.");
  }, []);
  return (
    <LoggerContainer>
      {data.Stations.map((t, i) => (
        <Logger
          key={"CardView_logger_" + i.toString()}
          head={t ? t.name : ""}
          headRight={t.location_name}
          location={t.datalogger_id}
          connected={t.status === "online"}
          device={t}
          selected={state.selectedDevices[t.id]}
          selectedToggle={selectedToggle}
        />
      ))}
      <AddLoggerComponent />
    </LoggerContainer>
  );
}
