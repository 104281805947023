import React from "react";
import styled from "react-emotion";

export const TextBox = styled("input")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 37,
    padding: '0 10px',
    margin: 0,
    borderRadius: 4,
    border: "0px solid transparent"
  })

  export const InputGroup = styled("div")({
    display: "block",
    width: "100%",
    margin: "10px 0"
  })

export const Input = (props) => {
    const {
        type = "text",
        label = "",
        value = "",
        onChange = (e) => {},
        labelColor = "#FFF"
    } = props;
    return (
        <InputGroup>
            { label && <label style={{color: labelColor}}>{label}</label> }
            <TextBox type={type} value={value} onChange={(e) => onChange(e, label)} />
        </InputGroup>
    )
}

export default Input;