import React, { useEffect, useContext } from "react";
import { Link } from "@reach/router";
import gql from "graphql-tag";
import { Query } from "react-apollo";


const variables = {
    "clientId": "CR1000X_1291"
};
const GET_RECENT_TABLES = gql`
query getRecentTables($clientId: String!){
  Data_records(
    limit: 100
    order: "reverse:data_record_id"
    where: { value: { clientId: $clientId } }
  ) {
    data_record_id
    table_name
  }
}`

const DataHeader = props =>
{
    const { client_id, selected_table } = props;
    return (
        <Query
              query={GET_RECENT_TABLES}
              variables={{
                clientId: client_id
              }}
            >
            {({ data, loading, error, subscribeToMore }) =>
            {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                if (!data || !data.Data_records || data.Data_records.length < 1) return "This station doesn't currently have any tables."
                let objCheck = {};
                return data.Data_records.reduce((prev, curr, i) =>
                {
                    const { table_name } = curr;
                    if (objCheck[table_name]) return prev;
                    objCheck[table_name] = true;
                    return [...prev, (
                        <Link key={`${client_id}_${table_name}`} to={`/data/${client_id}/${table_name}`} style={{padding: 20}}>
                            {table_name}
                        </Link>)]
                }, []);
              }}
            </Query>
    )
}

export default DataHeader;