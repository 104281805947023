export default {
  primary: {
    color: '#213a4b',
    dark: '#0b202b',
    medium: '#314d66',
    lightMedium: '#3e637f',
    light: '#3e6380',
    vibrant: '#29c5ff',
    textOn: '#fff',
    fontFamily: '"Montserrat", sans-serif'
  },
  neutral: {
    color: '#9a9a9a',
    light: '#b5b5b5',
    extraLight: '#cbcbcb',
    extraExtraLight: '#e9e9e9',
    dark: '#747474'
  },
  success: {
    color: '#42b851'
  },
  blue: {
    color: '#347bed',
    dark: '#266ee0',
    light: '#5b99ff',
    extraLight: '#8fb9ff',
    alpha1: 'rgba(52, 123, 237, 0.1)',
    alpha2: 'rgba(52, 123, 237, 0.2)'
  },
  green: {
    color: '#9ccc64'
  },
  red: {
    color: '#ED5851',
    light: '#f56962'
  },
  orange: {
    color: '#ffa22a',
    light: '#ffae46'
  },
  grid: {
    grid_breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    },
    max_container_width: {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140
    },
    column_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    },
    outer_gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    }
  },
  shadows: {
    id5: 'inset 0 19px 38px rgba(0,0,0,0.30), inset 0 15px 12px rgba(0,0,0,0.22)',
    id4: 'inset 0 14px 28px rgba(0,0,0,0.25), inset 0 10px 10px rgba(0,0,0,0.22)',
    id3: 'inset 0 10px 20px rgba(0,0,0,0.19), inset 0 6px 6px rgba(0,0,0,0.23)',
    id2: 'inset 0 3px 6px rgba(0,0,0,0.16), inset 0 3px 6px rgba(0,0,0,0.23)',
    id1: 'inset 0 1px 3px rgba(0,0,0,0.12), inset 0 1px 2px rgba(0,0,0,0.24)',
    d1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    d2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    d3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    d4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    d5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
  }
}
