import styled from "react-emotion";

export const Button = styled("button")(props => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "Uppercase",
  width: "100%",
  padding: ".2rem 1rem",
  borderRadius: "9px",
  fontSize: "1.6rem",
  backgroundColor: "transparent",
  border: props.border ? props.border : "1px solid #fff",
  color: "white",
  cursor: "pointer",
  height: 50,
  marginBottom: props.marginBottom ? props.marginBottom : 0,
  marginTop: props.marginTop ? props.marginTop : 0,
  "&:hover": {
    backgroundColor: "rgba(255,255,255,.1)"
  }
}));

export const LinkButton = styled(Button)`
  border: none;
`;
export const DropButton = styled(LinkButton)`
  border: none;
  margin: 0 0;
  border-radius: 0px;
`;
