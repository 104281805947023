import React, { useContext, useState } from "react";
import { Link, Location } from "@reach/router";
import styled, { css } from "react-emotion";
import { setUserLogout } from "../../actions";
import { PopoverBody } from "../FunctionBar";
import { DropButton } from "../shell/Button";
import Popover from "react-popover";
import { routes } from "../../App";
import { ContextAuth } from "../../reducers/AuthContext";
import { ContextApp } from "../../reducers/AppContext";
import { ReactComponent as Logo } from "../../icons/logo.svg";
import { ReactComponent as File } from "../../icons/file.svg";

export const NavigationNav = styled("nav")(props => ({
  backgroundColor: "#222",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: props.expand ? "270px" : "60px",
  transition: "width 200ms",
  zIndex: 2
}));

export const NavButton = styled("div")(props => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: "0px 15px",
  ":hover": {
    backgroundColor: props.selected ? "#0085e2" : "rgba(255,255,255,0.1)"
  },
  backgroundColor: props.selected ? "#026cb6" : "transparent"
}));

let popoverProps = {
  preferPlace: "below",
  place: "below",
  body: [<h1 key="a">Popover Title</h1>, <div key="b">Popover contents</div>]
};

const LinkCss = css`
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration-line: none;
  letter-spacing: 2px;
  width: 100%;
  font-size: 1.7rem;
  font-family: "poppinslight";
`;

function Navigation(props) {
  const { stateApp, dispatchApp } = useContext(ContextApp);
  const [accountPoppedOver, setAccountPoppedOver] = useState(false);
  const { state } = useContext(ContextAuth);
  const { expanded } = stateApp;
  const { user } = state;
  // const [expand, setExpand] = useState(true);

  const setExpand = payload => {
    dispatchApp({ type: "SET_EXPANDED", payload });
  };

  // const signOut = () => {
  //   dispatch(setUserLogout());
  // };

  const filteredRoutes = routes
    ? routes.filter(
        route =>
          route.name !== "Account" &&
          route.displayOnHeader === true &&
          route.minUserGroupId >=
            (user && user.user_group_id ? user.user_group_id : 99999)
      )
    : [];
  const uRoute = routes.find(r => r.name === "Account");
  return (
    <NavigationNav expand={expanded}>
      <NavButton className={LinkCss} onClick={e => setExpand(!expanded)}>
        <Logo
          style={{
            maxWidth: "4.5rem",
            maxHeight: "5rem",
            width: "4.5rem",
            height: "5rem",
            margin: "0.7rem 0px 0.25rem -0.55rem",
            color: "white"
          }}
          alt="Logo"
        />
      </NavButton>
      <Location>
        {({ location }) => {
          return [
            <Popover
              key={"auth_key"}
              {...popoverProps}
              isOpen={accountPoppedOver}
              style={{ zIndex: 400 }}
              onOuterAction={() => setAccountPoppedOver(false)}
              body={
                <PopoverBody>
                  <h1>Account</h1>
                  <DropButton onClick={() => setUserLogout()}>
                    Logout
                  </DropButton>
                </PopoverBody>
              }
            >
              <NavButton
                key={"route_link_9999"}
                selected={location.pathname === uRoute.route}
              >
                <a
                  className={LinkCss}
                  href="javascript:void(0)"
                  onClick={() => setAccountPoppedOver(!accountPoppedOver)}
                >
                  {uRoute ? uRoute.component() : null} &nbsp;{" "}
                  {expanded && uRoute.name}
                </a>
              </NavButton>
            </Popover>,
            ...filteredRoutes.map((route, i) => (
              <NavButton
                key={"route_link_" + i}
                selected={location.pathname === route.route}
              >
                <Link className={LinkCss} to={route.route}>
                  {route ? route.component() : null} &nbsp;{" "}
                  {expanded && route.name}
                </Link>
              </NavButton>
            )),
            <NavButton
                key={"route_link_docs"}
              >
              <a
                className={LinkCss}
                href="http://dl-mqtt.campbellsci.cloud"
                target="_blank"
              >
                <File
                  style={{
                    maxWidth: "4.5rem",
                    maxHeight: "5rem",
                    width: "4.5rem",
                    height: "5rem"
                  }}
                  alt="Docs"
                /> &nbsp;{" "}
                  {expanded && "MQTT API Docs"}
                </a>
              </NavButton>
          ];
        }}
      </Location>
    </NavigationNav>
  );
}

export default Navigation;
