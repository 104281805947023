import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import store from "./store.js";
import { Provider } from "react-redux";

// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./fonts/stylesheet.css";

ReactDOM.render(
  <Provider store={store}>
    <App style={{ width: "100vw" }} />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
