import React, { useState, useContext } from "react";
import styled from "react-emotion";
import { Button, LinkButton } from "../shell/Button";
import { Box, Header } from "../shell/Shell";
import { Link } from "@reach/router";
import { resetPassword } from "../../actions";
import Input from "../shell/Input";
import { ContextAuth } from "../../reducers/AuthContext";

export const CenteredForgotPassword = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh"
});

function ForgotPassword(props) {
  const [Username, setUsername] = useState("");
  const { dispatch } = useContext(ContextAuth);

  resetPassword = async () => {
    // console.log(JSON.stringify(this.state, null, '\t'))
    const payload = await resetPassword({ Username, token: props.token });
    dispatch({ action: "SET_AUTH", payload });
  };
  return (
    <CenteredForgotPassword>
      <Box>
        <Header>Forgot Password</Header>
        <Input
          type="text"
          label="Username"
          value={Username}
          onChange={e => setUsername(e.target.value)}
        />
        <Button
          marginBottom="15px"
          marginTop="20px"
          onClick={() => this.resetPassword()}
        >
          Reset Password
        </Button>
        <Link to={`/login`}>
          <LinkButton>return to login</LinkButton>
        </Link>
      </Box>
    </CenteredForgotPassword>
  );
}

export default ForgotPassword;
