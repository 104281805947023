import { fetchIt, config, downloadObject, baseUrl } from "./api";
import axios from "axios";
import { TokenKind } from "graphql";

export const setDevices = devices => ({
  type: "SET_DEVICES",
  payload: devices
});

export const setSelectedDevices = selectedDevices => ({
  type: "SET_SELECTED_DEVICES",
  payload: selectedDevices
});

export const setFiles = files => ({
  type: "SET_FILES",
  payload: files
});

export const setOses = oses => ({
  type: "SET_OSES",
  payload: oses
});

export const setSelectedFile = selectedFile => ({
  type: "SET_SELECTED_FILE",
  payload: selectedFile
});

export const setLoggedInUser = user => ({
  type: "SET_LOGGED_IN_USER",
  payload: user
});

export const setUserLogout = () => ({
  type: "LOGOUT_USER"
});

export const setAuthState = authState => ({
  type: "SET_AUTH_STATE",
  payload: authState
});

export const setShowAll = payload => ({
  type: "SET_SHOW_ALL",
  payload
});

export const setViewDataDevice = viewDataDevice => ({
  type: "SET_VIEW_DATA_DEVICE",
  payload: viewDataDevice
});

export const setDeviceTableData = deviceTableData => ({
  type: "SET_DEVICE_TABLE_DATA",
  payload: deviceTableData
});

export const setServices = services => ({
  type: "SET_SERVICES",
  payload: services
});

export const getAllCrBasicPrograms = () => {
  return new Promise((res, rej) => {
    let con = config();
    fetchIt(
      { ...con, method: "GET" },
      "/api/files/crbasic",
      data => {
        // console.log(data);
        res(data.bucketNames);
      },
      handleError
    );
  });
};

export const getAllOsFiles = () => {
  return new Promise((res, rej) => {
    let con = config();
    fetchIt(
      { ...con, method: "GET" },
      "/api/files/os",
      data => {
        console.log(data);
        res(data.bucketNames);
      },
      handleError
    );
  });
};

const getSelectedDataloggerIdsArray = (selectedDevices, devices) => {
  return Object.values(selectedDevices).map(device => {
    // console.log("getSelectedDataLoggerIdsArray: ", device, device);
    return { ...device };
  });
};

export const sendCrBasicProgramToSelectedDataloggers = (
  fileName,
  bucketName,
  { devices, selectedDevices }
) => {
  console.log("sD: ", selectedDevices, "D: ", devices);
  const dataloggers = getSelectedDataloggerIdsArray(selectedDevices, devices);
  const sendThisToTheServer = {
    fileName,
    bucketName,
    dataloggers
  };
  let con = config(sendThisToTheServer);

  return new Promise((res, rej) => {
    fetchIt(
      { ...con, method: "POST" },
      "/api/files/crbasic",
      data => {
        console.log(data);
        res(data);
      },
      handleError
    );
  });
};

export const sendOsToSelectedDataloggers = (
  fileName,
  bucketName,
  { devices, selectedDevices }
) => {
  console.log("sD: ", selectedDevices, "D: ", devices);
  const dataloggers = getSelectedDataloggerIdsArray(selectedDevices, devices);
  const sendThisToTheServer = {
    fileName,
    bucketName,
    dataloggers
  };
  let con = config(sendThisToTheServer);
  // console.log(con);
  return new Promise((res, rej) => {
    fetchIt(
      { ...con, method: "POST" },
      "/api/files/os",
      data => {
        console.log(data);
        res(data);
      },
      handleError
    );
  });
};

export const updateMqttToSelectedDataloggers = ({
  devices,
  selectedDevices
}) => {
  const dataloggers = getSelectedDataloggerIdsArray(selectedDevices, devices);
  const sendThisToTheServer = {
    dataloggers: dataloggers
  };
  let con = config(sendThisToTheServer);
  return new Promise((res, rej) => {
    fetchIt(
      {
        ...con,
        method: "POST"
      },
      "/api/thing/updateMqtt",
      data => {
        res(data);
        console.log(data);
      },
      handleError
    );
  });
};

export const textSignUp = phone => {
  const sendThisToTheServer = {
    phone
  };
  let con = config(sendThisToTheServer);
  return new Promise((res, rej) => {
    fetchIt(
      { ...con, method: "POST" },
      "/api/textsignup",
      data => {
        res(data);
      },
      handleError
    );
  });
};

export const showData = device => (dispatch, getState) => {
  dispatch(setViewDataDevice(device));
  // getTableData(device)(dispatch, getState);
};

export const login = async payload => {
  let con = config(payload);
  const xAuth = con.headers["x-auth-token"];
  con = { ...con, method: "POST" };
  console.log("actions: login.");
  return new Promise((res, rej) => {
    fetchIt(
      con,
      "/api/auth/login",
      data => {
        res(data);
      },
      err => handleError(err) && rej(err)
    );
  });
};

export const validatetoken = () => {
  return new Promise((res, rej) => {
    let con = config();
    con = { ...con, method: "GET" };
    fetchIt(
      con,
      "/api/auth/validatetoken",
      data => {
        const d = typeof data === "string" ? JSON.parse(data) : data;
        res(d);
      },
      err => handleError(err) && rej(err)
    );
  });
};

export const autoLogin = payload => {
  return new Promise((res, rej) => {
    let con = config(payload);
    fetchIt(
      { ...con, method: "POST" },
      "/api/auth/autologin",
      data => res(data),
      err => handleError(err) && rej(err)
    );
  });
};

export const resetPassword = payload => {
  return new Promise((res, rej) => {
    let con = config(payload);
    fetchIt(
      { ...con, method: "POST" },
      "/api/auth/reset",
      data => res(data),
      err => handleError(err) && rej(err)
    );
  });
};

export const confirmReset = payload => {
  return new Promise((res, rej) => {
    let con = config(payload);
    fetchIt(
      { ...con, method: "POST" },
      "/api/auth/resetconfirm",
      data => res(data),
      err => handleError(err) && rej(err)
    );
  });
};

export const updatePassword = payload => {
  return new Promise((res, rej) => {
    let con = config(payload);
    fetchIt(
      { ...con, method: "POST" },
      "/api/auth/updatepassword",
      data => res(data),
      err => handleError(err) && rej(err)
    );
  });
};

export const refreshToken = payload => {
  return new Promise((res, rej) => {
    let con = config(payload);
    const { csauth, ...headers } = con.headers;
    con = { ...con, headers, method: "POST" };

    fetchIt(
      con,
      "/api/auth/refreshtoken",
      data => res(data),
      err => handleError(err) && rej(err)
    );
  });
};

export const getNatsService = () => {
  let con = config();
  con = { ...con, method: "GET" };
  return new Promise((res, rej) => {
    fetchIt(
      con,
      "/api/nats/services",
      data => {
        console.log("NATS services: ", data);
        res(data);
      },
      handleError
    );
  });
};

const noop = () => {};

export const uploadOs = ({
  file,
  type,
  onProgress = noop,
  onComplete = noop,
  onError = noop
}) => {
  console.log("trying to upload:", type);
  let data = new FormData();
  data.append("file", file);
  var config = {
    onUploadProgress: onProgress
  };
  axios
    .post(`${baseUrl}/api/files/os/upload/${type}`, data, config)
    .then(onComplete)
    .catch(onError);
};

export const uploadCrBasic = ({
  file,
  type,
  onProgress = noop,
  onComplete = noop,
  onError = noop
}) => {
  console.log("trying to upload:", type);
  let data = new FormData();
  data.append("file", file);
  var config = {
    onUploadProgress: onProgress
  };
  axios
    .post(`${baseUrl}/api/files/crbasic/upload/${type}`, data, config)
    .then(onComplete)
    .catch(onError);
};

export const getOnBoardToken = payload => {
  let con = config(payload);
  con = { ...con, method: "POST" };

  return new Promise((res, rej) => {
    fetchIt(
      con,
      "/api/registration/onboardtoken",
      data => {
        downloadObject(data, "onboard");
        res(data);
      },
      handleError
    );
  });
};

export const viewLocation = station => {};

export const handleError = err => {
  console.log("error: ", err);
};
