import React, { useContext } from "react";
import { css } from "@emotion/core";
import { showData } from "../../actions";
import { GET_TABLE_DATA, SUBSCRIBE_DATA_TABLE } from "../../api";
import { Query } from "react-apollo";
import TableDisplay from "../TableDisplay";
import DataHeader from "../DataHeader";
import { Shade, HomeDiv, LoggerButton, ContainerFluid } from "../shell/Shell";
import Navigation from "../shell/Navigation";
import { routes } from "../../App";
import {
  ContextDeviceProvider,
  ContextDevice
} from "../../reducers/DeviceContext";
import { ReactComponent as DeleteButton } from "../../icons/cancel.svg";

export default function Data(props) {
  //http://localhost:3000/data/CR1000X_321/temp_Rh

  const viewData = e => {
    e.stopPropagation();
    showData(props.device);
  };

  const { viewDataDevice, table, device } = props;
  return (
    <Shade className="App d-flex flex-column flex-grow">
      <ContainerFluid>
        <Navigation routes={routes} />
        <HomeDiv className="home">
          <div style={{ alignItems: "center", padding: "60px 30px" }}>
            <div style={{ alignItems: "center" }}>
              {false && <h1>
                {viewDataDevice ? viewDataDevice.name : "Device is undefined"}
              </h1>}
              <DataHeader client_id={device} />
              {
                table ?
                  <ContextDeviceProvider>
                    <Query
                      query={GET_TABLE_DATA}
                      variables={{
                        client_id: device,
                        table_name: table
                      }}
                    >
                      {({ data, loading, error, subscribeToMore }) =>
                      {
                        if (loading) return "Loading...";
                        if (error) return `Error! ${error.message}`;
                        // var data = result && result.data ? result.data : {};
                        // return (<div>{ result ? JSON.stringify(result) : "No Data." }</div>);
                        const cols = Object.keys(
                          data.Data_records &&
                            data.Data_records[0] &&
                            data.Data_records[0].value &&
                            data.Data_records[0].value.dataSet
                            ? data.Data_records[0].value.dataSet
                            : { none: "" }
                        );
                        return (
                          <DataConnector
                            {...props}
                            cols={cols}
                            data={data}
                            subscribeToNewDataRecords={() =>
                              // something is broken that prevents a where clause to work correctly if
                              // a paramaterized query is sent.  hence the replace functions below:
                              subscribeToMore({
                                document: SUBSCRIBE_DATA_TABLE(query =>
                                {
                                  return query
                                    .replace(
                                      /\$client_id/,
                                      viewDataDevice && viewDataDevice.datalogger_id
                                        ? viewDataDevice.datalogger_id
                                        : "CR1000X_001"
                                    )
                                    .replace(/\$table_name/, table);
                                }),
                                variables: {},
                                updateQuery: (prev, { subscriptionData }) =>
                                {
                                  if (!subscriptionData.data) return prev;
                                  // console.log(
                                  //   "subscription data: ",
                                  //   subscriptionData.data
                                  // );
                                  // console.log("prev data: ", prev);
                                  const newFeedItem =
                                    subscriptionData.data.data_record_changed;

                                  return {
                                    Data_records: [
                                      newFeedItem,
                                      ...prev.Data_records
                                    ]
                                  };
                                }
                              })
                            }
                          />
                        );
                      }}
                    </Query>
                  </ContextDeviceProvider>
                  :
                  <h1 style={{margin: 30}}>
                    Please Select a table above to View Your Data.
                  </h1>
              }
            </div>
          </div>
          <LoggerButton onClick={viewData}>
            <DeleteButton
              alt="Close Data"
              css={css`{ width: "50px", color: "#000"}`}
            />
          </LoggerButton>
        </HomeDiv>
      </ContainerFluid>
    </Shade>
  );
}

function DataConnector(props) {
  const { stateDevice, dispatchDevice } = useContext(ContextDevice);
  console.log(stateDevice);
  return <TableDisplay {...stateDevice} {...props} dispatch={dispatchDevice} />;
}
