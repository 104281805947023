import React, { useContext } from "react";
import { ContextApp } from "../../reducers/AppContext";
import styled from "react-emotion";
import { ThemeProvider } from "emotion-theming";
import theme from "../../theme";
import { Button } from "./Button";

export const ContainerFluid = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  padding: "0",
  width: "100%"
});

const ShadeContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  fontFamily: "poppinslight",
  width: "100%",
  height: "100vh",
  margin: "0",
  padding: "0",
  background: "rgba(0,0,0,.05)"
});

const HomeD = styled("div")(props => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 60px)",
  margin: "0 -30px 0 60px",
  width: "calc(100% - 60px)",
  transition: "width 200ms",
  overFlowY: "auto"
}));

export const HomeDiv = props => {
  const { stateApp } = useContext(ContextApp);
  const { expanded } = stateApp;
  return (
    <HomeD {...props} expanded={expanded}>
      {props.children}
    </HomeD>
  );
};

export const LoggerButton = styled(Button)({
  borderColor: "transparent",
  color: "#222",
  position: "fixed",
  top: "80px",
  right: "10px",
  width: "92px"
});

export const Box = styled("div")({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#417bbf",
  alignItems: "left",
  flexDirection: "column",
  width: "95%",
  "@media(min-width: 400px)": {
    width: "85%"
  },
  "@media(min-width: 768px)": {
    width: "60%"
  },
  "@media(min-width: 1028px)": {
    width: "45%"
  },
  "@media(min-width: 1515px)": {
    width: "30%"
  },
  padding: "50px"
});

export const Header = styled("h1")({
  display: "flex",
  justifyContent: "center",
  fontWeight: "200",
  width: "auto",
  color: "#fff"
});

export const Shade = props => {
  return (
    <ThemeProvider theme={theme}>
      <ShadeContainer>{props.children}</ShadeContainer>
    </ThemeProvider>
  );
};
