import { GoogleMaps } from "../GoogleMaps";
import { Loggers } from "./Home";
import React from "react";
import MapMarkerCard from "../MapMarkerCard";
import { ContextDeviceProvider } from "../../reducers/DeviceContext";

function Map(props) {
  // const { state, dispatch } = useContext(ContextDevice);
  return (
    <Loggers
      render={prps => (
        <ContextDeviceProvider>
          <GoogleMaps
            {...{ ...prps, ...props }}
            render={station => <MapMarkerCard device={station} {...prps} />}
          />
        </ContextDeviceProvider>
      )}
    />
  );
}

export default Map;
