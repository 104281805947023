import * as React from "react";
let ContextDevice = React.createContext();

const initialState = {
  devices: [],
  selectedDevices: {},
  atLeastOneSelected: false,
  viewDataDevice: {},
  deviceTableData: [],
  showAll: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DEVICES":
      return { ...state, devices: action.payload };
    case "SET_LOGGER":
      return {
        ...state,
        devices: state.devices.map(logger =>
          logger.clientId === action.payload.clientId
            ? { ...logger, ...action.payload }
            : logger
        )
      };
    case "SET_SELECTED_DEVICES":
      return {
        ...state,
        selectedDevices: action.payload,
        atLeastOneSelected:
          Object.keys(action.payload).find(k => action.payload[k]) !== undefined
      };
    case "SET_VIEW_DATA_DEVICE":
      return { ...state, viewDataDevice: action.payload };
    case "SET_DEVICE_TABLE_DATA":
      return { ...state, deviceTableData: action.payload };
    case "TOGGLE_DEVICE_SHOW_ALL":
      return { ...state, showAll: !state.showAll };
    case "ADD_ONE_DEVICE_TABLE_DATA":
      return {
        ...state,
        deviceTableData:
          state.viewDataDevice !== undefined &&
          state.viewDataDevice.thingName !== undefined &&
          state.viewDataDevice.thingName === action.payload.clientId
            ? [action.payload, ...state.deviceTableData]
            : state.deviceTableData
      };
    default:
      return state;
  }
};

function ContextDeviceProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { stateDevice: state, dispatchDevice: dispatch };
  // console.log("ContextDeviceProvider", state, dispatch);
  return (
    <ContextDevice.Provider value={value}>
      {props.children}
    </ContextDevice.Provider>
  );
}

let ContextDeviceConsumer = ContextDevice.Consumer;
export { ContextDevice, ContextDeviceProvider, ContextDeviceConsumer };
// export default reducer;
