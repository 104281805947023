import React, { useEffect } from "react";
import styled from "react-emotion";

export const Tablo = styled("table")({
  // width: '100%',
  "> thead tr": {
    backgroundColor: "#343a40",
    color: "#FFF",
    padding: "20px",
    textTransform: "uppercase"
  },
  "> tbody tr:nth-child(even)": {
    backgroundColor: "#eee",
    color: "#222"
  },
  "> tbody tr:nth-child(odd)": {
    backgroundColor: "#fff",
    color: "#222"
  },
  " tr > td": {
    padding: "15px 15px"
  }
});

export default function TableDisplay(props) {
  useEffect(() => {
    props.subscribeToNewDataRecords();
  }, []);

  const { data, cols } = props;
  const hasCols = cols && cols.length && cols.length > 0;
  console.log(props);
  return (
    <Tablo>
      <thead>
        <tr>
          <td>Time</td>
          {hasCols
            ? cols.map(key => <td key={`header_${key}`}>{key}</td>)
            : null
          //h.map(column => <td key={`header_${column.key}`}>{column.label}</td>)
          }
        </tr>
      </thead>
      <tbody>
        {hasCols &&
          data &&
          data.Data_records.map((row, i) => (
            <tr key={`row_${row.data_record_id}`}>
              <td>{row.value["time"]}</td>
              {cols.map(column => (
                <td key={`${row.time}_${column}`}>
                  {row.value.dataSet[column]}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </Tablo>
  );
}
