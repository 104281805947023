// import openSocket from 'socket.io-client';
import { ApolloClient } from "apollo-boost";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";
import gql from "graphql-tag";
const getUrl = window.location;
const isDev =
  getUrl.protocol + "//" + getUrl.host + "/" === "http://localhost:3000/";
export let baseUrl = isDev
  ? "http://localhost:3001"
  : getUrl.protocol + "//" + getUrl.host;

// export let baseUrl = "https://cloud.campbellsci.com";

export const GRAPHQL_API_URL = `${baseUrl}/graphql`;
export const GRAPHQL_API_WS = `ws${baseUrl.split(/http/)[1]}`;

export const getClient = csAuth => {
  const csauth = csAuth ? csAuth : localStorage.getItem("cs-auth");
  // console.log("GRAPHQL_API_URL: ", GRAPHQL_API_URL);
  // console.log("GRAPHQL_WS: ", GRAPHQL_API_WS);
  // Create an http link:
  const httpLink = new HttpLink({
    uri: GRAPHQL_API_URL,
    headers: { "x-auth-token": csauth }
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: GRAPHQL_API_WS,
    headers: { "x-auth-token": csauth },
    options: {
      reconnect: true
    }
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    // By default, this client will send queries to the
    // `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link,
    cache: new InMemoryCache()
  });
};

const getHeaders = () => {
  const csauth = localStorage.getItem("cs-auth");
  return {
    "content-type": "application/json",
    "x-auth-token": csauth
  };
};

export const fetchIt = async (config, url, success, fail) => {
  return fetch(baseUrl + url, config)
    .then(handleResponse)
    .then(data => success(data))
    .catch(error => fail(error));
};

export const config = data => ({
  body: JSON.stringify(data), // must match 'Content-Type' header
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: isDev ? "include" : "same-origin", // include, same-origin, *omit
  headers: getHeaders(),
  method: "GET", // *GET, POST, PUT, DELETE, etc.
  mode: "cors", // no-cors, cors, *same-origin
  redirect: "follow", // *manual, follow, error
  referrer: "no-referrer" // *client, no-referrer
});

function handleResponse(response) {
  let contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return handleJSONResponse(response);
  } else if (contentType === undefined || contentType.includes("text/html")) {
    return handleTextResponse(response);
  } else {
    // Other response types as necessary. I haven't found a need for them yet though.
    throw new Error(`Sorry, content-type ${contentType} not supported`);
  }
}

function handleJSONResponse(response) {
  return response !== ""
    ? response.json().then(json => {
        if (response.ok) {
          return json;
        } else {
          return Promise.reject(
            Object.assign({}, json, {
              status: response.status,
              statusText: response.statusText
            })
          );
        }
      })
    : [];
}
function handleTextResponse(response) {
  return response.text().then(text => {
    if (response.ok) {
      return text;
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text
      });
    }
  });
}

// "9223372036854775807"
export const GET_STATIONS = gql`
  query get_stations($bot_entity_id: String!, $top_entity_id: String!) {
    Stations(
      limit: 100
      where: { or: { entity_id: { gte: $bot_entity_id, lte: $top_entity_id } } }
    ) {
      id
      datalogger_id
      station_id
      entity_id
      name
      data
      status
      location_name
      file_transfer_status
    }
  }
`;

export const CREATE_STATION = gql`
  mutation createStation(
    $name: String!
    $entity_id: String!
    $datalogger_id: String!
    $type: String!
    $data: SequelizeJSON!
  ) {
    createStation(
      name: $name
      entity_id: $entity_id
      datalogger_id: $datalogger_id
      data: $data
      status: "new"
      hardware_type: $type
    ) {
      id
      datalogger_id
      station_id
      entity_id
      hardware_type
      name
      data
      status
      location_name
      file_transfer_status
    }
  }
`;


export const SUBSCRIBE_STATION = fn =>
  gql`
    ${fn(`
    subscription {
    station_changed(where: {entity_id: "$entity_id"}) {
      id
      datalogger_id
      station_id
      entity_id
      name
      data
      status
      location_name
      file_transfer_status
    }
  }`
    )}
  `;

export const UPDATE_STATION = gql`
  mutation updateStation(
    $name: String!
    $customer_id: String!
    $datalogger_id: String!
    $data: SequelizeJSON!
    $station_id: String!
  ) {
    updateStation(
      station_id: $station_id
      name: $name
      entity_id: $customer_id
      datalogger_id: $datalogger_id
      data: $data
    ) {
      id
      datalogger_id
      station_id
      entity_id
      name
      data
      status
      location_name
      file_transfer_status
    }
  }
`;

export const DELETE_STATION = gql`
  mutation deleteStation($station_id: String!, $datalogger_id: String!) {
    deleteStation(
      where: { station_id: $station_id, datalogger_id: $datalogger_id }
    ) {
      station_id
    }
  }
`;

export const GET_TABLE_DATA = gql`
  query getTableData($client_id: String!, $table_name: String!) {
    Data_records(
      limit: 100
      order: "reverse:data_record_id"
      where: { value: { clientId: $client_id }, table_name: $table_name }
    ) {
      data_record_id
      value
    }
  }
`;

const CREATE_TABLE_DATA_variables = {
  "table_name": "panelTempC",
  "timestamp": "1560693900",
  "value": {
    "clientId": "CR1000X_001",
    "table": "panelTempC",
    "time": "2019-06-16T14:05:00",
    "dataSet": {
      "crTemp": 26.15
    }
  }
};

export const CREATE_TABLE_DATA = gql`
mutation createTableData($timestamp: String!, $table_name: String!, $value: SequelizeJSON!) {
  createData_record(
    timestamp: $timestamp,
    table_name: $table_name,
    value: $value
  ) {
    data_record_id
    value
  }
}
`;

export const GET_USER_DATA = gql`
  query getUser($cognito_id: String!) {
    User_accounts(where: { federated_id: $cognito_id }) {
      username
      entity {
        name
        marcom_id
      }
      user_group {
        user_group_id
      }
    }
  }
`;

export const CREATE_SCHEMA = gql`
  mutation createSchema(
    $name: String
    $data: SequelizeJSON
    $entity_id: String!
    $datalogger_id: String!
  ) {
    createSchema(
      name: $name
      data: $data
      entity_id: $entity_id
      datalogger_id: $datalogger_id
    ) {
      schema_id
      name
      data
      entity_id
      datalogger_id
      version
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_SCHEMA_TEST = {
  name: "tableList",
  data: {
    tableList: ["panelTempC", "loggerBattery", "testTable1"]
  },
  entity_id: "1",
  datalogger_id: "CR1000X_1291"
};

export const GET_SCHEMA = gql`
  query getUserByDataloggerId($datalogger_id: String!, $entity_id: String!) {
    schemas(
      limit: 100
      where: { datalogger_id: $datalogger_id, entity_id: $entity_id }
    ) {
      schema_id
      data
      name
      entity_id
      datalogger_id
      version
      createdAt
      updatedAt
    }
  }
`;

export const GET_SCHEMA_TEST = {
  datalogger_id: "CR1000X_1291",
  entity_id: "1"
};

export const GET_ALL_SETTINGS = gql`
  {
    Settings {
      setting_id
      sub_type
      sub_sub_type
      type
      data
    }
  }
`;

export const GET_SETTING = gql`
  query getSetting($type: String!, $sub_type: String!, $sub_sub_type: String!) {
    Settings(
      where: { type: $type, sub_type: $sub_type, sub_sub_type: $sub_sub_type }
    ) {
      setting_id
      type
      sub_type
      sub_sub_type
      data
    }
  }
`;

export const GET_SETTING_TEST = {
  type: "system",
  sub_type: "general",
  sub_sub_type: "general"
};

export const UPDATE_SETTING = gql`
  mutation updateSetting(
    $id: String!
    $type: String!
    $sub_type: String!
    $sub_sub_type: String!
    $data: SequelizeJSON!
  ) {
    updateSetting(
      setting_id: $id
      type: $type
      sub_type: $sub_type
      sub_sub_type: $sub_sub_type
      data: $data
    ) {
      setting_id
      type
      sub_type
      sub_sub_type
      data
    }
  }
`;

export const UPDATE_SETTING_TEST = {
  id: "1",
  type: "system",
  sub_type: "general",
  sub_sub_type: "general",
  data: {
    deviceTypes: {
      CR1000X: {},
      CR300: {}
    }
  }
};

export const CREATE_SETTING = gql`
  mutation createSetting(
    $type: String!
    $sub_type: String!
    $sub_sub_type: String!
    $data: SequelizeJSON!
  ) {
    createSetting(
      type: $type
      sub_type: $sub_type
      sub_sub_type: $sub_sub_type
      data: $data
    ) {
      setting_id
      type
      sub_type
      sub_sub_type
      data
    }
  }
`;

export const CREATE_SETTING_TEST = {
  type: "system",
  sub_type: "general",
  sub_sub_type: "general",
  data: {
    deviceTypes: {
      CR1000X: {},
      CR300: {}
    }
  }
};

export const SUBSCRIBE_DATA_TABLE = fn =>
  gql`
    ${fn(`
subscription {
  data_record_changed(
    where: {
      table_name: "$table_name",
      value: {
        clientId: "$client_id"
      }
    }
  ){
    data_record_id,
    value
  }
}`)}
  `;

export const downloadObject = (exportObj, exportName) => {
  const downloadData =
    typeof exportObj === "string" ? exportObj : JSON.stringify(exportObj);
  var dataStr =
    "data:text/json;charset=utf-8," + encodeURIComponent(downloadData);
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};
