import React, { useContext } from "react";
import styled from "react-emotion";
import FunctionBar from "./FunctionBar";
import ToggleButton from "react-toggle-button";
import { ContextDevice } from "../reducers/DeviceContext";
import { ContextAuth } from "../reducers/AuthContext";

export const HeaderConfig = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "4rem",
  maxHeight: "4rem",
  minHeight: "4rem",
  margin: "0px",
  background: "linear-gradient(0deg, #ddd, white, white)",
  alignItems: "flex-end",
  justifyContent: "center",
  padding: "1rem"
});

function Header(props) {
  const { state: stateAuth } = useContext(ContextAuth);
  const { stateDevice, dispatchDevice } = useContext(ContextDevice);

  const { atLeastOneSelected, showAll } = stateDevice;
  const { user } = stateAuth;
  // console.log("user", user);
  const isAdmin = user && user.user_group_id ? user.user_group_id === 1 : false;
  if (atLeastOneSelected) {
    return <FunctionBar />;
  } else {
    return (
      <HeaderConfig>
        {isAdmin && (
          <ToggleButton
            style={{ paddingRight: 10 }}
            inactiveLabel={""}
            activeLabel={""}
            colors={{
              activeThumb: {
                base: "rgb(250,250,250)"
              },
              inactiveThumb: {
                base: "rgb(62,130,247)"
              },
              active: {
                base: "rgb(207,221,245)",
                hover: "rgb(177, 191, 215)"
              },
              inactive: {
                base: "rgb(65,66,68)",
                hover: "rgb(95,96,98)"
              }
            }}
            thumbStyle={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignSelf: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.24) 0px 2px 4px",
              borderRadius: "15px",
              boxSizing: "border-box",
              position: "unset",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(62, 130, 247)"
            }}
            thumbAnimateRange={[-10, 30]}
            thumbIcon={<span>all</span>}
            value={!showAll}
            onToggle={() => {
              console.log("trying to Toggle Show All:", showAll);
              dispatchDevice({ type: "TOGGLE_DEVICE_SHOW_ALL" });
            }}
          />
        )}
      </HeaderConfig>
    );
  }
}

export default Header;
