import * as React from "react";
let ContextApp = React.createContext();

const initialState = {
  showAll: false,
  services: [],
  expanded: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_ALL":
      return { ...state, showAll: action.payload };
    case "SET_SERVICES":
      return { ...state, services: action.payload };
    case "SET_EXPANDED":
      return { ...state, expanded: action.payload };
    default:
      return state;
  }
};

function ContextAppProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { stateApp: state, dispatchApp: dispatch };
  return (
    <ContextApp.Provider value={value}>{props.children}</ContextApp.Provider>
  );
}

let ContextAppConsumer = ContextApp.Consumer;

export { ContextApp, ContextAppProvider, ContextAppConsumer };

export default reducer;
