import React, { Component } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
require("codemirror/lib/codemirror.css");
require("codemirror/theme/material.css");
require("codemirror/theme/neat.css");
require("codemirror/mode/xml/xml.js");
require("codemirror/mode/javascript/javascript.js");

export default class CRBasic extends Component {
  componentDidMount() {}
  render() {
    const value = undefined;
    return (
      <div
        className="CRBasic"
        style={{ height: "100vh", backgroundColor: "#263238", color: "#fff" }}
      >
        Program
        <CodeMirror
          value={value}
          options={{
            mode: "javascript",
            theme: "material",
            lineNumbers: true
          }}
          onChange={(editor, value) => {
            console.log("uncontrolled", { value });
            console.log("editor", { editor });
          }}
        />
      </div>
    );
  }
}
